/* line 13, scss/70-modules/propertycarousel/_module.scss */
.m-listing-carousel {
	margin-bottom: 0;
	margin-left: auto;
	margin-right: auto;
	position: relative;
	max-width: 100%;
}

/* line 15, scss/70-modules/propertycarousel/_module.scss */
.m-listing-carousel:not(.m-listing-carousel--new) {
	margin-top: 20px;
}

/* line 28, scss/70-modules/propertycarousel/_module.scss */
.m-listing-carousel.is-singleslide .listing-carousel__stage {
	transition: transform 300ms;
	overflow: visible;
	white-space: nowrap;
}

/* line 34, scss/70-modules/propertycarousel/_module.scss */
.m-listing-carousel.is-loading .listing-carousel__stage {
	transition: none;
}

/* line 38, scss/70-modules/propertycarousel/_module.scss */
.m-listing-carousel.is-loading.goes-forward .icon.next::before,
.m-listing-carousel.is-loading.goes-backwards .icon.prev::before {
	transform: none;
	animation: spinner-spin 1.1s cubic-bezier(0.6, 0.2, 0, 0.8) infinite, spinner-fade 1.1s linear infinite;
	left: 25%;
	border-radius: 50%;
	content: '';
	border-width: 3px;
	border-style: solid;
	border-color: rgb(var(--color-global-positive)) rgb(var(--color-global-positive)) transparent transparent;
	position: absolute;
	width: 50%;
	height: 50%;
	top: 25%;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .m-listing-carousel.is-loading.goes-forward .icon.next::before, .is-rtl
.m-listing-carousel.is-loading.goes-backwards .icon.prev::before {
	left: auto;
	right: 25%;
}

/* line 54, scss/70-modules/propertycarousel/_module.scss */
.m-listing-carousel .c-paging-extended {
	margin-bottom: 0;
	margin-top: 10px;
}

/* line 59, scss/70-modules/propertycarousel/_module.scss */
.m-listing-carousel .paging__item.is-active {
	cursor: default;
}

/* line 64, scss/70-modules/propertycarousel/_module.scss */
.m-listing-carousel .carousel__title, .m-listing-carousel .carousel__subtitle {
	text-align: left;
	padding: 0 20px 20px;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .m-listing-carousel .carousel__title, .is-rtl .m-listing-carousel .carousel__subtitle {
	text-align: right;
}

/* line 70, scss/70-modules/propertycarousel/_module.scss */
.m-listing-carousel .carousel__title {
	color: rgb(var(--color-dynamic-section-title));
	font-size: 1.34em;
}

/* line 82, scss/70-modules/propertycarousel/_module.scss */
.m-listing-carousel .carousel__subtitle {
	font-size: 1.167em;
}

/* line 88, scss/70-modules/propertycarousel/_module.scss */
.c-tabs {
	z-index: 3;
}

/* line 91, scss/70-modules/propertycarousel/_module.scss */
.c-tabs h3.tabs__item--selected {
	font-size: inherit;
	line-height: inherit;
}

/* line 102, scss/70-modules/propertycarousel/_module.scss */
.listing-carousel__label-link {
	display: block;
	padding: 10px 20px;
	color: rgb(var(--color-dynamic-section-title));
	background-color: rgb(var(--color-global-title));
}

/* line 108, scss/70-modules/propertycarousel/_module.scss */
.listing-carousel__label-link:hover {
	color: rgb(var(--color-global-positive));
	background-color: rgb(var(--color-global-secondary));
}

/* line 115, scss/70-modules/propertycarousel/_module.scss */
.listing-carousel__wrapper {
	position: relative;
	width: 100%;
	box-sizing: border-box;
}

/* line 120, scss/70-modules/propertycarousel/_module.scss */
.listing-carousel__wrapper.content-box__wrapper:not(.content-box__wrapper--without-arrows) {
	padding: 20px 2px 10px;
}

/* line 129, scss/70-modules/propertycarousel/_module.scss */
.listing-carousel__wrapper.content-box__wrapper.content-box__wrapper--without-arrows {
	padding: 20px 20px 10px;
}

@media only screen and (max-width: 29.999em) {
	/* line 129, scss/70-modules/propertycarousel/_module.scss */
	.listing-carousel__wrapper.content-box__wrapper.content-box__wrapper--without-arrows {
		padding: 20px 10px 10px;
	}
}

/* line 138, scss/70-modules/propertycarousel/_module.scss */
.listing-carousel__wrapper .price__upon-request {
	padding-top: 0;
	white-space: nowrap;
	display: inline;
}

/* line 145, scss/70-modules/propertycarousel/_module.scss */
.listing-carousel__wrapper--vertical.content-box__wrapper {
	padding: 40px 0;
}

/* line 151, scss/70-modules/propertycarousel/_module.scss */
.listing-carousel__item {
	white-space: normal;
}

/* line 155, scss/70-modules/propertycarousel/_module.scss */
.listing-carousel__item-image {
	border: 1px solid rgb(var(--color-global-border));
}

/* line 160, scss/70-modules/propertycarousel/_module.scss */
.m-listing-carousel:not(.is-singleslide) .is-ready .listing-carousel__item:not(.is-visible) {
	display: none;
}

/* line 164, scss/70-modules/propertycarousel/_module.scss */
.m-listing-carousel--static .listing-carousel__item {
	visibility: hidden;
	opacity: 0;
}

/* line 168, scss/70-modules/propertycarousel/_module.scss */
.m-listing-carousel--static .listing-carousel__item.is-visible {
	visibility: visible;
	opacity: 1;
}

/* line 174, scss/70-modules/propertycarousel/_module.scss */
.listing-carousel__item.is-buffered {
	display: none;
}

/* line 178, scss/70-modules/propertycarousel/_module.scss */
.listing-carousel__item .imagebox__tag {
	line-height: 2em;
	border: 1px solid rgb(var(--color-global-border));
	background: rgb(var(--color-global-positive));
	box-sizing: border-box;
}

/* line 187, scss/70-modules/propertycarousel/_module.scss */
.listing-carousel__item .listings-item__info {
	display: flex;
	flex-wrap: wrap;
}

/* line 191, scss/70-modules/propertycarousel/_module.scss */
.listing-carousel__item .listings-item__info-container {
	max-height: 90px;
	padding: 20px;
	overflow: hidden;
	width: 100%;
	box-sizing: border-box;
}

/* line 199, scss/70-modules/propertycarousel/_module.scss */
.listing-carousel__item .listings-item__info--flex-align {
	display: flex;
	align-items: center;
}

/* line 205, scss/70-modules/propertycarousel/_module.scss */
.listing-carousel__item .listings-item__location-info {
	text-align: left;
	flex: 0 0 auto;
	order: 1;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .listing-carousel__item .listings-item__location-info {
	text-align: right;
}

@media only screen and (max-width: 29.999em) {
	/* line 205, scss/70-modules/propertycarousel/_module.scss */
	.listing-carousel__item .listings-item__location-info {
		display: inline-block;
		max-width: 100%;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		display: block;
		margin-right: 15px;
	}
}

/* line 217, scss/70-modules/propertycarousel/_module.scss */
.listing-carousel__item .listings-item__characteristics-container {
	text-align: right;
	flex: 1 1 auto;
	position: relative;
	overflow: hidden;
	order: 2;
	min-height: 4em;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .listing-carousel__item .listings-item__characteristics-container {
	text-align: left;
}

/* line 227, scss/70-modules/propertycarousel/_module.scss */
.listing-carousel__item .listings-item__characteristics-container__wrapper {
	position: absolute;
	right: 0;
	left: 0;
}

/* line 233, scss/70-modules/propertycarousel/_module.scss */
.listing-carousel__item .listings-item__characteristics-placeholder {
	display: inline-block;
	margin: 0 0 40px 0;
}

/* line 238, scss/70-modules/propertycarousel/_module.scss */
.listing-carousel__item .listings-item__characteristics-beds, .listing-carousel__item .listings-item__characteristics-baths, .listing-carousel__item .listings-item__characteristics-interior {
	display: inline-block;
	vertical-align: top;
}

/* line 245, scss/70-modules/propertycarousel/_module.scss */
.listing-carousel__item .listings-item__characteristics-beds {
	margin-right: 15px;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .listing-carousel__item .listings-item__characteristics-beds {
	margin-right: initial;
	margin-left: 15px;
}

@media screen and (min-width: 0\0) {
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .listing-carousel__item .listings-item__characteristics-beds {
		margin-right: 0;
	}
}

/* line 249, scss/70-modules/propertycarousel/_module.scss */
.listing-carousel__item .listings-item__characteristics-interior {
	margin-left: 15px;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .listing-carousel__item .listings-item__characteristics-interior {
	margin-left: initial;
	margin-right: 15px;
}

@media screen and (min-width: 0\0) {
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .listing-carousel__item .listings-item__characteristics-interior {
		margin-left: 0;
	}
}

/* line 253, scss/70-modules/propertycarousel/_module.scss */
.listing-carousel__item .listings-item__description {
	text-align: center;
	text-transform: uppercase;
	margin-bottom: 60px;
}

/* line 259, scss/70-modules/propertycarousel/_module.scss */
.listing-carousel__item .listings-item__description-last {
	margin-left: 10px;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .listing-carousel__item .listings-item__description-last {
	margin-left: initial;
	margin-right: 10px;
}

@media screen and (min-width: 0\0) {
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .listing-carousel__item .listings-item__description-last {
		margin-left: 0;
	}
}

/* line 263, scss/70-modules/propertycarousel/_module.scss */
.listing-carousel__item .listings-item__text-price {
	padding-bottom: 0;
	padding-right: 20px;
}

/* line 267, scss/70-modules/propertycarousel/_module.scss */
.listing-carousel__item .listings-item__text-price .c-price.is-expanded {
	display: inline-block;
}

/* line 272, scss/70-modules/propertycarousel/_module.scss */
.listing-carousel__item .listings-item__price {
	white-space: nowrap;
	font-size: 1.2em;
}

/* line 279, scss/70-modules/propertycarousel/_module.scss */
.listing-carousel__item .listings-item__text-location {
	display: inline-block;
	max-width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	display: block;
	font-family: var(--font-family-headings);
	color: rgb(var(--color-global-secondary));
	padding-bottom: 0;
	font-display: swap;
	font-size: 1.2em;
}

@media only screen and (min-width: 80em) {
	/* line 291, scss/70-modules/propertycarousel/_module.scss */
	.listing-carousel__item .listings-item__text-location.listings-item__text-location--right {
		text-align: right;
	}
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .listing-carousel__item .listings-item__text-location.listings-item__text-location--right {
		text-align: left;
	}
}

/* line 297, scss/70-modules/propertycarousel/_module.scss */
.listing-carousel__item .listings-item__text-location.listings-item__text-location--shrink {
	width: 180px;
	margin-bottom: 10px;
}

/* line 304, scss/70-modules/propertycarousel/_module.scss */
.listing-carousel__item .price__currency,
.listing-carousel__item .price__value,
.listing-carousel__item .c-price--default .price__value,
.listing-carousel__item .listing-item__feat__description {
	font-size: 1.2em;
}

/* line 315, scss/70-modules/propertycarousel/_module.scss */
.listing-carousel__item .listing-item__feat__value {
	font-size: 1.34em;
}

/* line 322, scss/70-modules/propertycarousel/_module.scss */
.listing-carousel__item .listing-item__feat__value, .listing-carousel__item .listing-item__feat__description {
	color: rgb(var(--color-global-helper));
}

/* line 330, scss/70-modules/propertycarousel/_module.scss */
.listing-carousel__action {
	top: 50%;
	transform: translate(-0, -50%);
	z-index: 3;
	font-size: 12px;
	font-size: 1rem;
	position: absolute;
	overflow: hidden;
}

/* line 337, scss/70-modules/propertycarousel/_module.scss */
.listing-carousel__action--left {
	left: 0;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .listing-carousel__action--left {
	left: auto;
	right: 0;
}

/* line 341, scss/70-modules/propertycarousel/_module.scss */
.listing-carousel__action--right {
	right: 0;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .listing-carousel__action--right {
	right: auto;
	left: 0;
}

/* line 347, scss/70-modules/propertycarousel/_module.scss */
.listing-carousel__action .o-imagenav {
	background-color: transparent;
	color: rgb(var(--color-global-secondary));
}

/* line 351, scss/70-modules/propertycarousel/_module.scss */
.listing-carousel__action .o-imagenav:hover {
	background-color: transparent;
	color: rgb(var(--color-global-secondary));
}

/* line 357, scss/70-modules/propertycarousel/_module.scss */
.listing-carousel__action--top {
	top: 0;
}

/* line 361, scss/70-modules/propertycarousel/_module.scss */
.listing-carousel__action--bottom {
	bottom: -5px;
	top: auto;
}

/* line 366, scss/70-modules/propertycarousel/_module.scss */
.listing-carousel__action--vertical {
	left: 50%;
	transform: translate(-50%, -0);
}

/* line 370, scss/70-modules/propertycarousel/_module.scss */
.listing-carousel__action--vertical .imagenav--simple .imagenav__action--prev {
	left: 0;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .listing-carousel__action--vertical .imagenav--simple .imagenav__action--prev {
	left: auto;
	right: 0;
}

/* line 374, scss/70-modules/propertycarousel/_module.scss */
.listing-carousel__action--vertical .imagenav--simple .imagenav__action--next {
	right: 0;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .listing-carousel__action--vertical .imagenav--simple .imagenav__action--next {
	right: auto;
	left: 0;
}

/* line 381, scss/70-modules/propertycarousel/_module.scss */
.listing-carousel__slides {
	z-index: 2;
	left: 0;
	position: relative;
	top: 0;
	width: 100%;
	height: 100%;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .listing-carousel__slides {
	left: auto;
	right: 0;
}

@media only screen and (max-width: 47.999em) {
	/* line 395, scss/70-modules/propertycarousel/_module.scss */
	.m-listing-carousel--vertical .listing-carousel__slides--single-row > .grid {
		overflow: hidden;
		white-space: nowrap;
	}
}

/* line 401, scss/70-modules/propertycarousel/_module.scss */
.m-listing-carousel--vertical .listing-carousel__slides--single-row .price__value {
	line-height: 1em;
}

/* line 407, scss/70-modules/propertycarousel/_module.scss */
.m-listing-carousel--horizontal:not(.is-singleslide) .listing-carousel__slides--single-row > .grid {
	overflow: hidden;
	white-space: nowrap;
}

/* line 413, scss/70-modules/propertycarousel/_module.scss */
.listing-carousel__slides--overflow {
	overflow-x: hidden;
}

/* line 418, scss/70-modules/propertycarousel/_module.scss */
.property-carousel__fixed-action {
	right: 0;
	z-index: 3;
	position: absolute;
	top: 0;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .property-carousel__fixed-action {
	right: auto;
	left: 0;
}

/* line 426, scss/70-modules/propertycarousel/_module.scss */
.listing-carousel__button-wrapper {
	margin: 2px 0;
}

/* line 445, scss/70-modules/propertycarousel/_module.scss */
.m-carousel--videocarousel {
	background: transparent;
}

/* line 448, scss/70-modules/propertycarousel/_module.scss */
.m-carousel--videocarousel .o-imagenav {
	background: transparent;
}

/* line 452, scss/70-modules/propertycarousel/_module.scss */
.m-carousel--videocarousel .carousel__caption-button {
	font-size: 1em;
	letter-spacing: 2px;
	color: rgb(var(--color-global-positive));
	margin-bottom: 20px;
	text-transform: uppercase;
	display: inline-block;
	padding-left: 0;
}

@media only screen and (max-width: 47.999em) {
	/* line 452, scss/70-modules/propertycarousel/_module.scss */
	.m-carousel--videocarousel .carousel__caption-button {
		font-size: 1.165em;
	}
}

@media only screen and (min-width: 48em) and (max-width: 79.999em) {
	/* line 452, scss/70-modules/propertycarousel/_module.scss */
	.m-carousel--videocarousel .carousel__caption-button {
		font-size: 1em;
	}
}

@media only screen and (min-width: 80em) {
	/* line 452, scss/70-modules/propertycarousel/_module.scss */
	.m-carousel--videocarousel .carousel__caption-button {
		font-size: 1em;
	}
}

/* line 461, scss/70-modules/propertycarousel/_module.scss */
.m-carousel--videocarousel .carousel__caption-button .icon {
	font-size: 0.834em;
}

@media only screen and (max-width: 47.999em) {
	/* line 461, scss/70-modules/propertycarousel/_module.scss */
	.m-carousel--videocarousel .carousel__caption-button .icon {
		font-size: 0.97161em;
	}
}

@media only screen and (min-width: 48em) and (max-width: 79.999em) {
	/* line 461, scss/70-modules/propertycarousel/_module.scss */
	.m-carousel--videocarousel .carousel__caption-button .icon {
		font-size: 0.834em;
	}
}

@media only screen and (min-width: 80em) {
	/* line 461, scss/70-modules/propertycarousel/_module.scss */
	.m-carousel--videocarousel .carousel__caption-button .icon {
		font-size: 0.834em;
	}
}

/* line 466, scss/70-modules/propertycarousel/_module.scss */
.m-carousel--videocarousel .carousel__container iframe {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}

@media only screen and (min-width: 60em) {
	/* line 474, scss/70-modules/propertycarousel/_module.scss */
	.m-carousel--videocarousel .carousel__container {
		border-left: 20px solid rgb(var(--color-global-positive));
		border-right: 20px solid rgb(var(--color-global-positive));
	}
}

/* line 481, scss/70-modules/propertycarousel/_module.scss */
.m-carousel--videocarousel .listing-carousel__wrapper.content-box__wrapper:not(.content-box__wrapper--without-arrows) {
	padding: 0;
}

/* line 485, scss/70-modules/propertycarousel/_module.scss */
.m-carousel--videocarousel .imagebox__tag {
	position: absolute;
	bottom: 70px;
	left: 2px;
	color: rgb(var(--color-global-positive));
}

/* line 492, scss/70-modules/propertycarousel/_module.scss */
.m-carousel--videocarousel .listings-item__text-location {
	margin-bottom: 10px;
}

/* line 497, scss/70-modules/propertycarousel/_module.scss */
.m-carousel--videocarousel .listings-item__text-location .locality {
	font-size: 1.833em;
	font-family: var(--font-family-headings);
	font-display: swap;
}

@media only screen and (max-width: 47.999em) {
	/* line 497, scss/70-modules/propertycarousel/_module.scss */
	.m-carousel--videocarousel .listings-item__text-location .locality {
		font-size: 2.13544em;
	}
}

@media only screen and (min-width: 48em) and (max-width: 79.999em) {
	/* line 497, scss/70-modules/propertycarousel/_module.scss */
	.m-carousel--videocarousel .listings-item__text-location .locality {
		font-size: 1.833em;
	}
}

@media only screen and (min-width: 80em) {
	/* line 497, scss/70-modules/propertycarousel/_module.scss */
	.m-carousel--videocarousel .listings-item__text-location .locality {
		font-size: 1.833em;
	}
}

/* line 502, scss/70-modules/propertycarousel/_module.scss */
.m-carousel--videocarousel .listings-item__text-location .locality.listings-item__text-location-title {
	font-size: 1.23em;
	display: block;
}

@media only screen and (max-width: 47.999em) {
	/* line 502, scss/70-modules/propertycarousel/_module.scss */
	.m-carousel--videocarousel .listings-item__text-location .locality.listings-item__text-location-title {
		font-size: 1.43295em;
	}
}

@media only screen and (min-width: 48em) and (max-width: 79.999em) {
	/* line 502, scss/70-modules/propertycarousel/_module.scss */
	.m-carousel--videocarousel .listings-item__text-location .locality.listings-item__text-location-title {
		font-size: 1.23em;
	}
}

@media only screen and (min-width: 80em) {
	/* line 502, scss/70-modules/propertycarousel/_module.scss */
	.m-carousel--videocarousel .listings-item__text-location .locality.listings-item__text-location-title {
		font-size: 1.23em;
	}
}

/* line 508, scss/70-modules/propertycarousel/_module.scss */
.m-carousel--videocarousel .is-next-left,
.m-carousel--videocarousel .is-next-right {
	top: 0;
}

/* line 513, scss/70-modules/propertycarousel/_module.scss */
.m-carousel--videocarousel .carousel__container iframe,
.m-carousel--videocarousel .carousel__container .imagebox__tag {
	z-index: 3;
	opacity: 100%;
	transition: opacity 0.5s linear;
	transition-delay: 0.5s;
}

/* line 521, scss/70-modules/propertycarousel/_module.scss */
.m-carousel--videocarousel .carousel__container:not(.is-center) iframe,
.m-carousel--videocarousel .carousel__container:not(.is-center) .imagebox__tag {
	opacity: 0;
}

/* line 526, scss/70-modules/propertycarousel/_module.scss */
.m-carousel--videocarousel .listing-carousel__more-link-wrapper {
	text-align: center;
	margin-bottom: 2px;
}

/* line 530, scss/70-modules/propertycarousel/_module.scss */
.m-carousel--videocarousel .listing-carousel__more-link {
	font-size: 1.167em;
	padding: 10px 20px;
	border-radius: 10px;
	background: #000;
}

@media only screen and (max-width: 47.999em) {
	/* line 530, scss/70-modules/propertycarousel/_module.scss */
	.m-carousel--videocarousel .listing-carousel__more-link {
		font-size: 1.35956em;
	}
}

@media only screen and (min-width: 48em) and (max-width: 79.999em) {
	/* line 530, scss/70-modules/propertycarousel/_module.scss */
	.m-carousel--videocarousel .listing-carousel__more-link {
		font-size: 1.167em;
	}
}

@media only screen and (min-width: 80em) {
	/* line 530, scss/70-modules/propertycarousel/_module.scss */
	.m-carousel--videocarousel .listing-carousel__more-link {
		font-size: 1.167em;
	}
}

/* line 539, scss/70-modules/propertycarousel/_module.scss */
.m-listing-carousel--new .listing-carousel__tabs {
	flex: 1 1 auto;
	align-self: end;
}

/* line 544, scss/70-modules/propertycarousel/_module.scss */
.m-listing-carousel--new .carousel__title {
	display: inline-block;
	max-width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	flex: 0 1 250px;
	padding: 0 20px;
	align-self: center;
	color: rgb(var(--color-dynamic-section-title));
	font-size: 1.34em;
	font-family: var(--font-family-text);
	font-weight: 600;
}

@media only screen and (max-width: 47.999em) {
	/* line 544, scss/70-modules/propertycarousel/_module.scss */
	.m-listing-carousel--new .carousel__title {
		font-size: 1.0519em;
	}
}

@media only screen and (min-width: 48em) and (max-width: 79.999em) {
	/* line 544, scss/70-modules/propertycarousel/_module.scss */
	.m-listing-carousel--new .carousel__title {
		font-size: 1.14838em;
	}
}

@media only screen and (min-width: 80em) {
	/* line 544, scss/70-modules/propertycarousel/_module.scss */
	.m-listing-carousel--new .carousel__title {
		font-size: 1.34em;
	}
}

/* line 563, scss/70-modules/propertycarousel/_module.scss */
.m-listing-carousel--new .top-navigation {
	text-align: right;
	flex: 0 1 250px;
}

/* line 568, scss/70-modules/propertycarousel/_module.scss */
.m-listing-carousel--new .mobile-tabs {
	flex: 1 1 0;
	align-self: center;
	margin-right: 10px;
}

/* line 574, scss/70-modules/propertycarousel/_module.scss */
.m-listing-carousel--new .listing-carousel__action--right {
	margin-right: 20px;
}

/* line 578, scss/70-modules/propertycarousel/_module.scss */
.m-listing-carousel--new .listing-carousel__action {
	position: relative;
	top: auto;
	left: auto;
	bottom: auto;
	right: auto;
	transform: none;
	display: inline-block;
	margin-top: 8px;
	margin-left: 5px;
	background: #fff;
	border-radius: 5px;
	width: 30px;
	height: 30px;
}

/* line 593, scss/70-modules/propertycarousel/_module.scss */
.m-listing-carousel--new .listing-carousel__action .o-imagenav {
	font-size: 1em;
	width: 30px;
	height: 30px;
}

/* line 600, scss/70-modules/propertycarousel/_module.scss */
.m-listing-carousel--new .c-tabs {
	margin-bottom: 0;
}

/* line 604, scss/70-modules/propertycarousel/_module.scss */
.m-listing-carousel--new .tabs__item {
	border: 1px solid #ccc;
	background: #f7f7f7;
	border-top-left-radius: 8px;
	border-top-right-radius: 8px;
	font-size: 0.834em !important;
	font-weight: 600;
	color: #58585a;
	width: 100%;
	max-width: 100px;
	padding: 10px;
}

@media only screen and (max-width: 47.999em) {
	/* line 604, scss/70-modules/propertycarousel/_module.scss */
	.m-listing-carousel--new .tabs__item {
		font-size: 0.97161em !important;
	}
}

@media only screen and (min-width: 48em) and (max-width: 79.999em) {
	/* line 604, scss/70-modules/propertycarousel/_module.scss */
	.m-listing-carousel--new .tabs__item {
		font-size: 0.834em !important;
	}
}

@media only screen and (min-width: 80em) {
	/* line 604, scss/70-modules/propertycarousel/_module.scss */
	.m-listing-carousel--new .tabs__item {
		font-size: 0.834em !important;
	}
}

/* line 623, scss/70-modules/propertycarousel/_module.scss */
.m-listing-carousel--new .tabs__item--selected {
	font-size: 0.834em !important;
}

@media only screen and (max-width: 47.999em) {
	/* line 623, scss/70-modules/propertycarousel/_module.scss */
	.m-listing-carousel--new .tabs__item--selected {
		font-size: 0.97161em !important;
	}
}

@media only screen and (min-width: 48em) and (max-width: 79.999em) {
	/* line 623, scss/70-modules/propertycarousel/_module.scss */
	.m-listing-carousel--new .tabs__item--selected {
		font-size: 0.834em !important;
	}
}

@media only screen and (min-width: 80em) {
	/* line 623, scss/70-modules/propertycarousel/_module.scss */
	.m-listing-carousel--new .tabs__item--selected {
		font-size: 0.834em !important;
	}
}

/* line 633, scss/70-modules/propertycarousel/_module.scss */
.m-listing-carousel--new .tabs__item.tabs__item--selected {
	border-bottom-color: rgb(var(--color-dynamic-background-accent));
	background: rgb(var(--color-dynamic-background-accent));
	color: #000c5b;
}

/* line 639, scss/70-modules/propertycarousel/_module.scss */
.m-listing-carousel--new .top-background {
	display: flex;
	width: 100%;
	background: #e8e8e8;
	height: 45px;
}

/* line 646, scss/70-modules/propertycarousel/_module.scss */
.m-listing-carousel--new .scroll-carousel__control.scroll-carousel__control--left,
.m-listing-carousel--new .scroll-carousel__control.scroll-carousel__control--right {
	display: none;
}

/* line 651, scss/70-modules/propertycarousel/_module.scss */
.m-listing-carousel--new .selectboxit-btn, .m-listing-carousel--new.selectboxit-enabled:hover {
	background: transparent;
	border: 0;
	font-weight: bold;
	font-size: 12px;
	color: #8d8d8d;
	text-align: right;
	text-transform: uppercase;
}

/* line 662, scss/70-modules/propertycarousel/_module.scss */
.m-listing-carousel--new .selectboxit-btn.selectboxit-enabled:hover .selectboxit-default-arrow {
	border-top-color: rgb(var(--color-global-secondary));
}

/* line 666, scss/70-modules/propertycarousel/_module.scss */
.m-listing-carousel--new .c-map-pagination {
	list-style: none;
	display: flex;
	align-items: center;
	justify-content: center;
}

/* line 673, scss/70-modules/propertycarousel/_module.scss */
.m-listing-carousel--new .c-map-pagination .bullet {
	margin: 0 2px;
}

/* line 677, scss/70-modules/propertycarousel/_module.scss */
.m-listing-carousel--new .c-map-pagination .bullet::after {
	display: block;
	content: "";
	width: 6px;
	height: 6px;
	border-radius: 100%;
	background-color: #999;
}

/* line 686, scss/70-modules/propertycarousel/_module.scss */
.m-listing-carousel--new .c-map-pagination .bullet.current::after {
	background-color: #070303;
}

@media only screen and (min-width: 60em) {
	/* line 693, scss/70-modules/propertycarousel/_module.scss */
	.m-listing-carousel--new .bullet-navigation,
	.m-listing-carousel--new .mobile-tabs {
		display: none !important;
	}
}

@media only screen and (max-width: 59.999em) {
	/* line 701, scss/70-modules/propertycarousel/_module.scss */
	.m-listing-carousel--new .top-navigation,
	.m-listing-carousel--new .listing-carousel__tabs {
		display: none !important;
	}
	/* line 706, scss/70-modules/propertycarousel/_module.scss */
	.m-listing-carousel--new .listing-carousel__slides {
		max-height: initial !important;
	}
}

/* line 711, scss/70-modules/propertycarousel/_module.scss */
.m-listing-carousel--new .listing-carousel__wrapper.content-box__wrapper:not(.content-box__wrapper--without-arrows) {
	padding: 20px 20px 10px;
	background-color: rgb(var(--color-dynamic-background-accent));
}

/* line 22, scss/80-themes/AffinityDefault/70-modules/propertycarousel/module.scss */
.paw-content .m-listing-carousel .listing-carousel__tabs {
	flex: 1 1 0;
	align-self: end;
}

/* line 27, scss/80-themes/AffinityDefault/70-modules/propertycarousel/module.scss */
.paw-content .m-listing-carousel .carousel__title {
	flex: 1 1 0;
	padding: 0 20px;
	align-self: center;
	color: rgb(var(--color-dynamic-section-title));
	font-size: 16px;
	font-weight: 600;
	margin-top: 8px;
}

/* line 37, scss/80-themes/AffinityDefault/70-modules/propertycarousel/module.scss */
.paw-content .m-listing-carousel .top-navigation {
	text-align: right;
	flex: 1 1 0;
}

/* line 42, scss/80-themes/AffinityDefault/70-modules/propertycarousel/module.scss */
.paw-content .m-listing-carousel .mobile-tabs {
	flex: 1 1 0;
	align-self: center;
	margin-right: 10px;
}

/* line 48, scss/80-themes/AffinityDefault/70-modules/propertycarousel/module.scss */
.paw-content .m-listing-carousel .listing-carousel__action--right {
	margin-right: 20px;
}

/* line 52, scss/80-themes/AffinityDefault/70-modules/propertycarousel/module.scss */
.paw-content .m-listing-carousel .listing-carousel__action {
	position: relative;
	top: auto;
	left: auto;
	bottom: auto;
	right: auto;
	transform: none;
	display: inline-block;
	margin-top: 8px;
	margin-left: 5px;
	background: #fff;
	border-radius: 5px;
	width: 30px;
	height: 30px;
}

/* line 67, scss/80-themes/AffinityDefault/70-modules/propertycarousel/module.scss */
.paw-content .m-listing-carousel .listing-carousel__action .o-imagenav {
	font-size: 1em;
	width: 30px;
	height: 30px;
}

/* line 74, scss/80-themes/AffinityDefault/70-modules/propertycarousel/module.scss */
.paw-content .m-listing-carousel .c-tabs {
	margin-bottom: 0;
}

/* line 78, scss/80-themes/AffinityDefault/70-modules/propertycarousel/module.scss */
.paw-content .m-listing-carousel .tabs__item {
	border: 1px solid #ccc;
	background: #f7f7f7;
	border-top-left-radius: 8px;
	border-top-right-radius: 8px;
	font-size: 10px;
	font-weight: 600;
	color: #58585a;
}

/* line 88, scss/80-themes/AffinityDefault/70-modules/propertycarousel/module.scss */
.paw-content .m-listing-carousel .tabs__item.tabs__item--selected {
	border-bottom-color: rgb(var(--color-dynamic-background-accent));
	background: rgb(var(--color-dynamic-background-accent));
	color: rgb(var(--color-dynamic-primary-color));
}

/* line 94, scss/80-themes/AffinityDefault/70-modules/propertycarousel/module.scss */
.paw-content .m-listing-carousel .top-background {
	display: flex;
	width: 100%;
	background: #e8e8e8;
	height: 45px;
}

/* line 101, scss/80-themes/AffinityDefault/70-modules/propertycarousel/module.scss */
.paw-content .m-listing-carousel .scroll-carousel__control.scroll-carousel__control--left,
.paw-content .m-listing-carousel .scroll-carousel__control.scroll-carousel__control--right {
	display: none;
}

/* line 106, scss/80-themes/AffinityDefault/70-modules/propertycarousel/module.scss */
.paw-content .m-listing-carousel .selectboxit-btn, .paw-content .m-listing-carousel.selectboxit-enabled:hover {
	background: transparent;
	border: 0;
	font-weight: bold;
	font-size: 12px;
	color: #8d8d8d;
	text-align: right;
	text-transform: uppercase;
}

/* line 117, scss/80-themes/AffinityDefault/70-modules/propertycarousel/module.scss */
.paw-content .m-listing-carousel .selectboxit-btn.selectboxit-enabled:hover .selectboxit-default-arrow {
	border-top-color: rgb(var(--color-global-secondary));
}

/* line 121, scss/80-themes/AffinityDefault/70-modules/propertycarousel/module.scss */
.paw-content .m-listing-carousel .c-map-pagination {
	list-style: none;
	display: flex;
	align-items: center;
	justify-content: center;
}

/* line 128, scss/80-themes/AffinityDefault/70-modules/propertycarousel/module.scss */
.paw-content .m-listing-carousel .c-map-pagination .bullet {
	margin: 0 2px;
}

/* line 132, scss/80-themes/AffinityDefault/70-modules/propertycarousel/module.scss */
.paw-content .m-listing-carousel .c-map-pagination .bullet::after {
	display: block;
	content: "";
	width: 6px;
	height: 6px;
	border-radius: 100%;
	background-color: #999;
}

/* line 141, scss/80-themes/AffinityDefault/70-modules/propertycarousel/module.scss */
.paw-content .m-listing-carousel .c-map-pagination .bullet.current::after {
	background-color: #070303;
}

@media only screen and (min-width: 60em) {
	/* line 147, scss/80-themes/AffinityDefault/70-modules/propertycarousel/module.scss */
	.paw-content .m-listing-carousel .bullet-navigation,
	.paw-content .m-listing-carousel .mobile-tabs {
		display: none !important;
	}
}

@media only screen and (max-width: 59.999em) {
	/* line 153, scss/80-themes/AffinityDefault/70-modules/propertycarousel/module.scss */
	.paw-content .m-listing-carousel .top-navigation,
	.paw-content .m-listing-carousel .listing-carousel__tabs {
		display: none !important;
	}
	/* line 158, scss/80-themes/AffinityDefault/70-modules/propertycarousel/module.scss */
	.paw-content .m-listing-carousel .listing-carousel__slides {
		max-height: initial !important;
	}
}

/* line 165, scss/80-themes/AffinityDefault/70-modules/propertycarousel/module.scss */
.paw-content .listing-carousel__wrapper.content-box__wrapper:not(.content-box__wrapper--without-arrows) {
	padding: 20px 20px 10px;
}

/*# sourceMappingURL=../../../../../true */